import React from 'react'
import PropTypes from 'prop-types'

const Checklist = ({ checklist_text }) => {
  return (
    <div className="row checklist breathe">
      <div className="container container-960">
        <div className="row">
          <div className="col-md-12">
            <div
              dangerouslySetInnerHTML={{
                __html: checklist_text,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
Checklist.propTypes = {
  checklist_text: PropTypes.string.isRequired,
}

export default Checklist
