import React from 'react'
import { Chart } from 'react-google-charts'
import { Watch } from 'scrollmonitor-react'

export default Watch(
  class Column extends React.Component {
    render() {
      let { data, vaxis, haxis } = this.props
      const options = {
        legend: {
          position: 'top',
          alignment: 'center',
          textStyle: {
            bold: true,
          },
        },
        vAxis: {
          title: vaxis,
        },
        hAxis: {
          title: haxis,
        },
        animation: {
          duration: 1000,
          easing: 'out',
          startup: true,
        },
      }

      return (
        <div>
          {this.props.isInViewport && (
            <Chart
              chartType="ColumnChart"
              options={options}
              data={data}
              width="100%"
              height="400px"
            />
          )}
        </div>
      )
    }
  }
)
