import React from 'react'
import PropTypes from 'prop-types'

const Intro = ({ intro_text }) => {
  return (
    <div className="row intro-text breathe">
      <div className="container container-960">
        <div className="row">
          <div className="col-md-12">
            <div
              dangerouslySetInnerHTML={{
                __html: intro_text,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
Intro.propTypes = {
  intro_text: PropTypes.string.isRequired,
}

export default Intro
