import React from 'react'
import { Chart } from 'react-google-charts'
import { Watch } from 'scrollmonitor-react'

export default Watch(
  class Line extends React.Component {
    render() {
      let { data, vaxis, haxis, title, ticks } = this.props
      const options = {
        legend: {
          position: 'top',
          alignment: 'center',
          textStyle: {
            bold: true,
          },
        },
        title: title,
        vAxis: {
          title: vaxis,
        },
        hAxis: {
          title: haxis,
          format: 'Y',
          ticks: ticks,
        },
        animation: {
          duration: 1000,
          easing: 'out',
          startup: true,
        },
      }
      const chartEvents = [
        {
          eventName: 'select',
          callback({ chartWrapper }) {
            chartWrapper.draw(chartWrapper.getChart().container, options)
            console.log('Selected ', chartWrapper.getChart().container)
          },
        },
      ]
      return (
        <div>
          {this.props.isInViewport && (
            <Chart
              width={'100%'}
              height={'400px'}
              chartType="LineChart"
              loader={<div>Loading Chart</div>}
              data={data}
              options={options}
              chartEvents={chartEvents}
            />
          )}
        </div>
      )
    }
  }
)
