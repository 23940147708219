import React from 'react'
import PropTypes from 'prop-types'
import Layout from '../components/Layout'
import { Link, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import BarLine from '../components/charts/BarLine'
import Column from '../components/charts/Column'
import Line from '../components/charts/Line'
import Area from '../components/charts/Area'
import Map from '../components/charts/Map'
import Bar from '../components/charts/Bar'
import BarDot from '../components/charts/BarDot'
import Donut from '../components/charts/Donut'
import Stacked from '../components/charts/Stacked'
import StackedColumn from '../components/charts/StackedColumn'
import logosml from '../images/logo-sml.svg'
import Summary from '../components/sections/summary'
import Recommendations from '../components/sections/recommendations'
import Quote from '../components/sections/quote'
import Figure from '../components/sections/figure'
import TextFigure from '../components/sections/textFigure'
import Stats from '../components/sections/stats'
import Checklist from '../components/sections/checklist'
import Table from '../components/sections/table'
import StandardText from '../components/sections/standardText'
import { ModalContext } from '../components/WrapPageElement'
import SolidButton from '../components/buttons/SolidButton'
import Intro from '../components/sections/intro'
import TwoBarWithLine from '../components/charts/TwoBarWithLine'
import Pie from '../components/charts/Pie'

export const PageTemplate = ({ title, acf }) => {
  return (
    <Layout>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={title} />
      </Helmet>
      <section className="row chapter breathe">
        <div className="container container-960">
          <div className="row">
            <div className="col-sm-4 chapter-logo">
              <Link to="/">
                <img src={logosml} />
              </Link>
            </div>
            <div className="col-sm-8 chapter-title">The AI Playbook</div>
          </div>
        </div>
        <div className="col-xs-12">
          <div className="row breathe-top">
            <div className="container container-960">
              <div className="row">
                <div className="col-md-12">
                  <h1>{title}</h1>
                </div>
              </div>
            </div>
          </div>
          {acf &&
            acf.sections_page &&
            acf.sections_page &&
            acf.sections_page.map((layout, i) => {
              if (layout.__typename === 'WordPressAcf_summary') {
                return <Summary key={i} summary_text={layout.summary_text} />
              }

              if (layout.__typename === 'WordPressAcf_recommendations') {
                return (
                  <Recommendations
                    key={i}
                    recommendations_text={layout.recommendations_text}
                  />
                )
              }

              if (layout.__typename === 'WordPressAcf_checklist') {
                return (
                  <Checklist key={i} checklist_text={layout.checklist_text} />
                )
              }

              if (layout.__typename === 'WordPressAcf_quote') {
                return (
                  <Quote
                    key={i}
                    quote_text={layout.quote_text}
                    name={layout.name}
                    company_cite={layout.company_cite}
                  />
                )
              }

              if (layout.__typename === 'WordPressAcf_figure') {
                return (
                  <div className="figure row breathe" key={i}>
                    <div className=" container container-960" key={i}>
                      <div className="row">
                        <Figure figure_repeater={layout.figure_repeater} />
                      </div>
                    </div>
                  </div>
                )
              }

              if (layout.__typename === 'WordPressAcf_text_figure') {
                return (
                  <TextFigure
                    key={i}
                    text_figure_heading={layout.text_figure_heading}
                    text_figure_text={layout.text_figure_text}
                    text_figure_source={layout.text_figure_source}
                  />
                )
              }

              if (layout.__typename === 'WordPressAcf_table') {
                return (
                  <Table
                    key={i}
                    table_heading={layout.table_heading}
                    table_data={layout.table_data}
                    table_source={layout.table_source}
                  />
                )
              }

              if (layout.__typename === 'WordPressAcf_intro') {
                return <Intro key={i} intro_text={layout.intro_text} />
              }
              if (layout.__typename === 'WordPressAcf_standard_text_block') {
                return (
                  <StandardText key={i} standard_text={layout.standard_text} />
                )
              }

              if (layout.__typename === 'WordPressAcf_chart') {
                if (layout.chart_type === 'BarLine') {
                  return (
                    <div key={i} className="row chart breathe ">
                      <div className="container container-960">
                        <div className="row">
                          <div className="col-md-12">
                            <h6>{layout.chart_title}</h6>
                            <BarLine
                              data={JSON.parse(layout.chart_data)}
                              vaxis={layout.vaxis}
                              haxis={layout.haxis}
                            />
                            <p className="source">{layout.chart_source}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }

                if (layout.chart_type === 'BarDot') {
                  return (
                    <div key={i} className="row chart breathe ">
                      <div className="container container-960">
                        <div className="row">
                          <div className="col-md-12">
                            <h6>{layout.chart_title}</h6>
                            <BarDot
                              data={JSON.parse(layout.chart_data)}
                              vaxis={layout.vaxis}
                              haxis={layout.haxis}
                            />
                            <p className="source">{layout.chart_source}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }

                if (layout.chart_type === 'Donut') {
                  return (
                    <div key={i} className="row chart breathe ">
                      <div className="container container-960">
                        <div className="row">
                          <div className="col-md-12">
                            <h6>{layout.chart_title}</h6>
                            <Donut
                              data={JSON.parse(layout.chart_data)}
                              vaxis={layout.vaxis}
                              haxis={layout.haxis}
                            />
                            <p className="source">{layout.chart_source}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }
                if (layout.chart_type === 'Map') {
                  return (
                    <div key={i} className="row chart breathe ">
                      <div className="container container-960">
                        <div className="row">
                          <div className="col-md-12">
                            <h6>{layout.chart_title}</h6>
                            <Map
                              data={JSON.parse(layout.chart_data)}
                              vaxis={layout.vaxis}
                              haxis={layout.haxis}
                            />
                            <p className="source">{layout.chart_source}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }

                if (layout.chart_type === 'Pie') {
                  return (
                    <div key={i} className="row chart breathe ">
                      <div className="container container-960">
                        <div className="row">
                          <div className="col-md-12">
                            <h6>{layout.chart_title}</h6>
                            <Pie
                              data={JSON.parse(layout.chart_data)}
                              vaxis={layout.vaxis}
                              haxis={layout.haxis}
                            />
                            <p className="source">{layout.chart_source}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }
                if (layout.chart_type === 'Stacked') {
                  return (
                    <div key={i} className="row chart breathe ">
                      <div className="container container-960">
                        <div className="row">
                          <div className="col-md-12">
                            <h6>{layout.chart_title}</h6>
                            <Stacked
                              data={JSON.parse(layout.chart_data)}
                              vaxis={layout.vaxis}
                              haxis={layout.haxis}
                            />
                            <p className="source">{layout.chart_source}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }

                if (layout.chart_type === 'StackedColumn') {
                  return (
                    <div key={i} className="row chart breathe ">
                      <div className="container container-960">
                        <div className="row">
                          <div className="col-md-12">
                            <h6>{layout.chart_title}</h6>
                            <StackedColumn
                              data={JSON.parse(layout.chart_data)}
                              vaxis={layout.vaxis}
                              haxis={layout.haxis}
                            />
                            <p className="source">{layout.chart_source}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }

                if (layout.chart_type === 'TwoBarWithLine') {
                  return (
                    <div key={i} className="row chart breathe ">
                      <div className="container container-960">
                        <div className="row">
                          <div className="col-md-12">
                            <h6>{layout.chart_title}</h6>
                            <TwoBarWithLine
                              data={JSON.parse(layout.chart_data)}
                              vaxis={layout.vaxis}
                              haxis={layout.haxis}
                            />
                            <p className="source">{layout.chart_source}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }

                if (layout.chart_type === 'Bar') {
                  return (
                    <div key={i} className="row chart breathe">
                      <div className="container container-960">
                        <div className="row">
                          <div className="col-md-12">
                            <h6>{layout.chart_title}</h6>
                            <Bar
                              data={JSON.parse(layout.chart_data)}
                              vaxis={layout.vaxis}
                              haxis={layout.haxis}
                            />
                            <p className="source">{layout.chart_source}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }

                if (layout.chart_type === 'Column') {
                  return (
                    <div key={i} className="row chart breathe">
                      <div className="container container-960">
                        <div className="row">
                          <div className="col-md-12">
                            <h6>{layout.chart_title}</h6>
                            <Column
                              data={JSON.parse(layout.chart_data)}
                              vaxis={layout.vaxis}
                              haxis={layout.haxis}
                            />
                            <p className="source">{layout.chart_source}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }

                if (layout.chart_type === 'Line') {
                  return (
                    <div key={i} className="row chart breathe">
                      <div className="container container-960">
                        <div className="row">
                          <div className="col-md-12">
                            <h6>{layout.chart_title}</h6>

                            <Line
                              data={JSON.parse(layout.chart_data)}
                              vaxis={layout.vaxis}
                              haxis={layout.haxis}
                              // ticks={layout.haxis}
                            />

                            <p className="source">{layout.chart_source}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }
              }

              if (layout.__typename === 'WordPressAcf_stats') {
                return (
                  <Stats
                    key={i}
                    stat={layout.stat}
                    stat_text={layout.stat_text}
                  />
                )
              }
            })}
        </div>
        <div className="container container-960">
          <div className="row">
            <div className="col-md-12">
              {acf &&
                acf.nav_buttons_page &&
                acf.nav_buttons_page.map((buttons, i) => {
                  if (buttons.__typename === 'WordPressAcf_previous_button') {
                    return (
                      <React.Fragment key={i}>
                        <span className="pull-left hidden-xs hidden-sm">
                          <SolidButton
                            url={buttons.previous_button_link.post_name}
                            label={buttons.previous_button_text}
                            arrow="left"
                            order={-1}
                          />
                        </span>
                        <span className="pull-left hidden-md hidden-lg">
                          <SolidButton
                            url={buttons.previous_button_link.post_name}
                            label="Previous"
                            arrow="left"
                            order={-1}
                          />
                        </span>
                      </React.Fragment>
                    )
                  }
                })}
              {acf &&
                acf.nav_buttons_page &&
                acf.nav_buttons_page.map((buttons, i) => {
                  if (buttons.__typename === 'WordPressAcf_next_button') {
                    return (
                      <React.Fragment key={i}>
                        <span className="pull-right hidden-xs hidden-sm">
                          <SolidButton
                            url={buttons.next_button_link.post_name}
                            arrow="right"
                            label={buttons.next_button_text}
                            order={1000000000}
                          />
                        </span>
                        <span className="pull-right hidden-md hidden-lg">
                          <SolidButton
                            url={buttons.next_button_link.post_name}
                            arrow="right"
                            label="Next"
                            order={1000000000}
                          />
                        </span>
                      </React.Fragment>
                    )
                  }
                })}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

PageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  acf: PropTypes.object,
}

const Page = ({ data }) => {
  const { wordpressPage: page } = data
  return (
    <ModalContext.Consumer>
      {aye => (
        <PageTemplate
          modalDisplay={aye}
          title={page.title}
          content={page.content}
          acf={page.acf}
        />
      )}
    </ModalContext.Consumer>
  )
}

Page.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Page

export const pageQuery = graphql`
  query GetChapter1($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      id
      content
      slug
      acf {
        nav_buttons_page {
          __typename
          ... on WordPressAcf_next_button {
            next_button_text
            next_button_link {
              post_name
            }
          }
          __typename
          ... on WordPressAcf_previous_button {
            previous_button_text
            previous_button_link {
              post_name
            }
          }
        }

        sections_page {
          __typename
          ... on WordPressAcf_summary {
            summary_text
          }
          __typename
          ... on WordPressAcf_quote {
            quote_text
            name
            company_cite: company
          }
          __typename
          ... on WordPressAcf_recommendations {
            recommendations_text
          }
          __typename
          ... on WordPressAcf_checklist {
            checklist_text
          }
          __typename
          ... on WordPressAcf_table {
            table_heading
            table_data
            table_source
          }
          __typename
          ... on WordPressAcf_intro {
            intro_text
          }
          __typename
          ... on WordPressAcf_standard_text_block {
            standard_text
          }

          __typename
          ... on WordPressAcf_chart {
            chart_type
            chart_data
            chart_title
            chart_source
            haxis
            vaxis
          }
          __typename
          ... on WordPressAcf_figure {
            figure_repeater {
              figure_heading
              figure_width
              figure_source
              figure_image {
                source_url
              }
            }
          }
          __typename
          ... on WordPressAcf_text_figure {
            text_figure_heading
            text_figure_text
            text_figure_source
          }
          __typename
          ... on WordPressAcf_stats {
            stat_text
            stat
          }
        }
      }
    }
    allWordpressWpApiMenusMenusItems(filter: { name: { eq: "test" } }) {
      edges {
        node {
          count
          items {
            order
            title
            object_slug
          }
        }
      }
    }
    allWordpressPage(sort: { fields: [menu_order] }) {
      edges {
        node {
          menu_order
          slug
          title
        }
      }
    }
  }
`
