import React from 'react'
import PropTypes from 'prop-types'

const TextFigure = ({ text_figure_heading, text_figure_text, text_figure_source }) => {
  return (
    <div className="row text-figure breathe">
      <div className="container container-960">
        <div className="row">
          <div className="col-md-12">
            <h6 dangerouslySetInnerHTML={{ __html: text_figure_heading }} />
            <div className="figure-text"
              dangerouslySetInnerHTML={{
                __html: text_figure_text,
              }}
            />
            <p className="source" dangerouslySetInnerHTML={{ __html: text_figure_source }} />
          </div>
        </div>
      </div>
    </div>
  )
}
TextFigure.propTypes = {
  text_figure_heading: PropTypes.string.isRequired,
  text_figure_text: PropTypes.string.isRequired,
  text_figure_source: PropTypes.string.isRequired,
}

export default TextFigure
