import React from 'react'
import PropTypes from 'prop-types'

const Quote = ({ quote_text, company_cite, name }) => {
  return (
    <div className="row quote breathe">
      <div className="container container-960">
        <div className="row">
          <div className="col-md-12">
            <blockquote>
              <p
                dangerouslySetInnerHTML={{
                  __html: quote_text,
                }}
              />
                <cite
                  dangerouslySetInnerHTML={{
                    __html: name,
                  }}
                />
              {company_cite && (
                <cite
                  dangerouslySetInnerHTML={{
                    __html: company_cite,
                  }}
                />
              )}
            </blockquote>
          </div>
        </div>
      </div>
    </div>
  )
}
Quote.propTypes = {
  company_cite: PropTypes.string.isRequired,
  quote_text: PropTypes.string.isRequired,
}

export default Quote
