import React from 'react'
import { Chart } from 'react-google-charts'
import { Watch } from 'scrollmonitor-react'

export default Watch(
  class BarLine extends React.Component {
    render() {
      let { data, vaxis, haxis, title } = this.props
      const options = {
        legend: {
          position: 'top',
          alignment: 'center',
          textStyle: {
            bold: true,
          },
        },
        title: title,
        vAxis: {
          0: { title: vaxis[0] },
          1: { title: vaxis[1] },
        },
        hAxis: {
          0: { title: haxis[0] },
          1: { title: haxis[1] },
        },
        seriesType: 'bars',
        animation: {
          duration: 1000,
          easing: 'out',
          startup: true,
        },
        series: {
          0: {
            color: '#B22222',
            targetAxisIndex: 0,
          },
          1: {
            color: 'orange',
            title: '',
            targetAxisIndex: 1,
            type: 'line',
            lineWidth: 3,
            pointShape: { type: 'circle' },
            pointStroke: { color: 'orange', fill: 'white', width: 3 },
            pointSize: 10,
          },
        },
      }

      return (
        <div>
          {this.props.isInViewport && (
            <Chart
              chartType="ComboChart"
              options={options}
              data={data}
              width="100%"
              height="400px"
              legendToggle
            />
          )}
        </div>
      )
    }
  }
)
